<template>
  <div class="BaseOnboardingStep">
    <div class="BaseOnboardingStep__steps">
      <template v-if="!noSteps">
        <div
          :style="{ width: currentStepWidth }"
          class="BaseOnboardingStep__step-fill"
        />

        <div class="BaseOnboardingStep__step-current z-index-1 is-position-relative">
          {{ $t('pages.onboarding.step') }} {{ step }} / {{ totalSteps }}
        </div>
      </template>
    </div>

    <div class="BaseOnboardingStep__body columns is-marginless">
      <slot name="body">
        <div class="column">
          <div class="BaseOnboardingStep__innerBody p-a-m">
            <slot />
          </div>
        </div>

        <div
          :style="{ backgroundImage:`url('${ coverImage }')` }"
          class="column BaseOnboardingStep__cover"
        >
          <slot name="cover">
            <div class="BaseOnboardingStep__cover-text">
              {{ coverText }}
            </div>
          </slot>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
import { percentage } from '@hypefactors/shared/js/utils/numberUtils.js'

export default {
  name: 'BaseOnboardingStep',

  props: {
    coverImage: {
      type: String,
      default: null
    },

    coverText: {
      type: String,
      default: null
    },

    step: {
      type: Number,
      default: null
    },

    totalSteps: {
      type: Number,
      default: null
    }
  },

  computed: {
    currentStepWidth () {
      return percentage(this.step / this.totalSteps) + '%'
    },
    noSteps () {
      return this.step === null
    }
  }
}
</script>

<style lang="scss">
@import '~utils';

.OnboardingStep {
  &__actions {
    padding-top: $padding;
  }
}

.BaseOnboardingStep {
  background: $white;
  box-shadow: $hf__card-shadow;
  min-height: 100vh;
  display: flex;
  flex-flow: column;
  @include transit();
  @include tablet {
    min-height: 550px;
  }

  &__body {
    flex: 1 1 auto;
    display: flex;
    padding-bottom: 3rem;
    @include tablet {
      padding-bottom: 0;
    }
  }

  &__innerBody {
    display: flex;
    height: 100%;
    & > * {
      width: 100%;
      display: flex;
      flex-flow: column;
    }
  }

  &__steps {
    color: $white;
    font-weight: bold;
    font-size: $size-4;
    background: $grey;
    position: relative;
    height: 40px;
    padding: $padding-small $padding-large;
  }

  &__step-fill {
    background: $primary;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;

    @include transit($time: .7s)
  }

  &__cover {
    background: no-repeat center/cover $grey;
    display: none;
    font-weight: bold;
    font-size: $size-3;
    color: $white;
    position: relative;

    @include tablet {
      display: block;
      min-height: 350px;
    }

    &-text {
      text-align: center;
      z-index: 1;
      position: relative;
    }
  }
}
</style>
